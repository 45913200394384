import React from 'react';
import { AfaObj } from '../Data';
import TitleText from '../TitleSubtitle/TitleText';
import styled from 'styled-components';

const AfA = () => {
  return (
      <MainContainer>
      <TitleText title={AfaObj.title} text={AfaObj.text} id={AfaObj.id}/>
      </MainContainer>
  );
};

export default AfA;


export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: 800px; */
    position: relative;
    z-index: 1; 
    margin-bottom: 20px;
`  
