import styled from "styled-components";
import React, {useState} from 'react'; 
import { StyleDataObj } from "../StyleData";
import SubtitleText from "../TitleSubtitle/SubtitleText";
import {VorteilQCG_ArbeitgeberInnen as ArbeitgeberInnen} from '../Data';
import {VorteilQCG_ArbeitnehmerInnen as ArbeitnehmerInnen} from '../Data';



const VorteilQCG = () => { 

    const [hover, setHover] = useState(false)


  return (
  <MainContainer id='vorteilQCG'>
    <Row1>
        <MainH2 id="vorteile">
            Wem bringt das QCG etwas?
        </MainH2>
    </Row1>
    <Row2> 
        <SubtitleText title={ArbeitgeberInnen.subtitle} text={ArbeitgeberInnen.text} id={ArbeitgeberInnen.id}/>
    </Row2>
    <Row2>
        <SubtitleText title={ArbeitnehmerInnen.subtitle} text={ArbeitnehmerInnen.text} id={ArbeitnehmerInnen.id}/>
    </Row2> 
  </MainContainer>
  );
};

export default VorteilQCG; 


export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: 800px; */
    position: relative;
    z-index: 1; 
` 

export const Row1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
` 

export const MainH2 = styled.h2`
    
    color: black;
    font-size: ${StyleDataObj.H1fontSize}px;
    text-align: center;
    margin: 10px;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
` 

export const Row2 = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px 0;
` 

export const Subtitle = styled.h3` 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`  

export const Text = styled.p` 
    color: black;
    font-size: ${StyleDataObj.pfontSize}px;
    text-align: ${StyleDataObj.p_text_align}; 
    height: 200px;
    overflow: visible auto;
` 