import React from 'react';
import { SidebarContainer, Icon, CloseIcon,
 SidebarWrapper, SidebarMenu,
 SidebarLink, SideBtnWrap, SidebarRoute
 } from './SidebarElements';

export const Sidebar = ({isOpen, toggle}) => {
  return (
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
          <Icon onClick={toggle}>
              <CloseIcon/>
          </Icon> 
          <SidebarWrapper>
              <SidebarMenu id="SidebarMenu">
                  <SidebarLink to="Einzelcoaching_GruppenkursMix" onClick={toggle}> Einzelcoaching </SidebarLink>
                  <SidebarLink to="IndividuellesBewerbungscoaching" onClick={toggle}>
                  Bewerbungscoaching
                  </SidebarLink>
                  <SidebarLink to="ZertifizierteBildungsträger" onClick={toggle}>
                  Bildungsträger
                  </SidebarLink>
                  <SidebarLink to="antragstellung" onClick={toggle}> Antragstellung </SidebarLink>
              </SidebarMenu>
              <SideBtnWrap>
                  <SidebarRoute to="/kontakt">Kontakt</SidebarRoute>
              </SideBtnWrap>
          </SidebarWrapper>
      </SidebarContainer>
  );
};
