import React from 'react';
import styled from 'styled-components';

import { GefMassnObj } from '../Data';
import TitleText from '../TitleSubtitle/TitleText';

const GefMassnahmen = () => {
  return (
      <MainContainer id="massnahmen">
        <TitleText title={GefMassnObj.title} text={GefMassnObj.text} id={GefMassnObj.id}/>
      </MainContainer>
  );
};

export default GefMassnahmen;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: 800px; */
    position: relative;
    z-index: 1; 
    margin-bottom: 20px;
` 