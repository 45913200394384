import React from 'react';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard,
ServicesH2, ServicesIcon, ServicesP, StyledLink } from './BtaKurseElements'; 
import DTM from '../../../images/undraw_personal_settings_manager.svg'
import Coach from '../../../images/undraw_people_coache.svg'
import Agile from '../../../images/undraw_scrum_board.svg'
import SubtitleText from '../../arbeitswelt/TitleSubtitle/SubtitleText'; 
import { BtaHilfe_Kursauswahl } from '../../arbeitswelt/Data';
import { BTA_Traeger } from '../../arbeitswelt/Data';
import styled from 'styled-components';


const BTAKurse = () => {
  return (
    <>
      <ServicesContainer id="ZertifizierteBildungsträger">
        <ServicesH1>Die BTA als Zertifizierter Bildungsträger</ServicesH1>
        <ServicesH2>Weiterbildungsbeispiele</ServicesH2>
        <ServicesWrapper>
            
            <StyledLink href="https://www.bta-weiterbildung.de/weiterbildungen/digital_transformation_management" target="_blank">
                <ServicesCard>
                    
                    <ServicesIcon src={DTM}/>
                    <ServicesH2> Digital Transformation Management (6 Monate)</ServicesH2>
                    <ServicesP>Als Digital Transformation Manager gestaltest du die wesentlichen Zukunftsthemen in Unternehmen und Organisationen mit.</ServicesP>
                </ServicesCard>
            </StyledLink>

            <StyledLink href="https://www.bta-weiterbildung.de/weiterbildungen/agile-coach" target="_blank">
                <ServicesCard>
                    <ServicesIcon src={Coach}/>
                    <ServicesH2> Agile Coach (6 Monate)</ServicesH2>
                    <ServicesP>Agile Coaches leiten Workshops und Schulungen, entwickeln und beraten Teams und Führungskräfte in allen Fragestellungen rund um Agilität.</ServicesP>
                </ServicesCard>
            </StyledLink>

                
            <StyledLink href="https://www.bta-weiterbildung.de/weiterbildungen/agiles-management" target="_blank">
                <ServicesCard>
                    <ServicesIcon src={Agile}/>
                    <ServicesH2> Agiles Management (1 Monat)</ServicesH2>
                    <ServicesP>
                    Agiles Management wird immer gefragter. Lerne wie du auf die Herausforderungen der modernen Arbeitswelt reagieren und Anpassungen vornehmen kannst. 
                    </ServicesP>
                </ServicesCard>
            </StyledLink>
        </ServicesWrapper>

      </ServicesContainer>
      
      <TextContainer>
        <SubtitleText title={BTA_Traeger.subtitle} text={BTA_Traeger.text} id={BTA_Traeger.id}/>
      </TextContainer>
    </>
  );
};

export default BTAKurse;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: 800px; */
    position: relative;
    z-index: 1; 
` 