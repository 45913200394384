import styled from "styled-components";
import React, {useState} from 'react'; 
import { StyleDataObj } from "../StyleData";
import Pic2 from '../../../images/Education_Vector_2.png'
import Pic3 from '../../../images/Education_Vector_3.png'
import PicSvg2 from '../../../images/Grafik_Qualifizierungschancengesetz_A4.jpg'

import { Voraussetzungen } from '../Data';
import TitleText from '../TitleSubtitle/TitleText';

const Voraussetzung = () => { 

    const [hover, setHover] = useState(false)
    const [alt, setAlt] = useState("false") 

    const onHover = () =>{
        setHover(!hover)
    }

  return (
  <MainContainer id='home'>
    <TitleText title={Voraussetzungen.title} text={Voraussetzungen.text} id={Voraussetzungen.id}/>
 
    <Row3> 
        <ImgWrap>
            <Img src={PicSvg2} alt={alt}/> 
        </ImgWrap>
    </Row3>
    {/* <Row4> 
        <ImgWrap>
            <Img src={Pic2} alt={alt}/>
            <Img src={Pic3} alt={alt}/> 
        </ImgWrap>
    </Row4> */}
    
  </MainContainer>
  );
};

export default Voraussetzung; 


export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    position: relative;
    z-index: 1; 
    
` 


export const Row2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
` 

export const Row3 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
` 

export const Row4 = styled.div`
    display: flex;
    flex-direction: column;
` 

export const MainH2 = styled.h2`
    
    color: black;
    font-size: ${StyleDataObj.H2fontSize}px;
    text-align: center;
    margin-bottom: 10px;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`  

 

export const Text1 = styled.p`
    
    color: black;
    font-size: ${StyleDataObj.pfontSize}px;
    text-align: ${StyleDataObj.p_text_align};
    margin-bottom: 20px;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`  

export const ImgWrap = styled.div` 
    height: 100%;
    display: flex;
    flex-direction: row;

    margin: 0 20px;

    @media screen and (max-width: 768px){
        width: 500px;
    }
    @media screen and (max-width: 576px){
        width: 400px;
    }
    @media screen and (max-width: 400px){
        width: 350px;
    }
`

export const Img = styled.img` 
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`