import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Kontakt from '../components/Kontakt';

const KontaktPage = () => {
  return (
  <div>
    <ScrollToTop/>
    <Kontakt/>
  </div>
  );
};

export default KontaktPage;
