import {Container, Form, FormButton, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, Text, FormInputLong } from './KontaktElements';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Kontaktformular = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_euu4c2y', 'template_ge8hi0h', form.current, 'yF2u6JuDkPdRTbQTv')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
      <>
        <FormContent>
            <Form action='#' ref={form} onSubmit={sendEmail}>
                <FormH1> Kontaktformular</FormH1>
                <FormLabel htmlFor='for'>Vor- und Nachname</FormLabel>
                <FormInput name="user_name" type="text" required/>
                <FormLabel htmlFor='for'>Email</FormLabel>
                <FormInput name="user_email" type="email" required/>
                <FormLabel htmlFor='for'>Telefon</FormLabel>
                <FormInput type="tel" name="user_tel" />
                <FormLabel htmlFor='for'>Nachricht</FormLabel>
                <FormInputLong name="message" type="text" required/>  
                <FormButton type="submit"  value="Send">Mehr erfahren</FormButton>
            </Form>
        </FormContent>
      </>
  );
};

export default Kontaktformular;
