export const StyleDataObj = {
    H1fontSize: 32,
    H2fontSize: 24,
    H3fontSize: 20,
    pfontSize: 16,
    p_text_align: "justify",

    H1_media_screen_max_width_768px_font_size: 40,

    H1_media_screen_max_width_480px_font_size: 32,

} 
