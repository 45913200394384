import React from 'react';
import styled from 'styled-components';

import { StyleDataObj } from "../StyleData";

import { BtaHilfe_Kursauswahl, BtaHilfe_Antragsstellung} from '../Data';
import SubtitleText from '../TitleSubtitle/SubtitleText';

const BTA_Hilfe = () => {
  return (
    <>
        <MainH2 id="antragstellung">
            So hilft dir die BTA bei deiner Antragstellung
        </MainH2>
        
        <MainContainer> 
            <SubtitleText title={BtaHilfe_Kursauswahl.subtitle} text={BtaHilfe_Kursauswahl.text} id={BtaHilfe_Kursauswahl.id}/>
        </MainContainer>
        <MainContainer> 
            <SubtitleText title={BtaHilfe_Antragsstellung.subtitle} text={BtaHilfe_Antragsstellung.text} id={BtaHilfe_Antragsstellung.id}/>
        </MainContainer>
    </>
  );
};

export default BTA_Hilfe;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: 800px; */
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
` 

export const MainH2 = styled.h2`
    word-wrap: break-word;
    color: black;
    font-size: ${StyleDataObj.H2fontSize}px;
    text-align: center;
    margin: 30px 0;

    @media screen and (max-width: 768px){
        font-size: ${StyleDataObj.H3fontSize}px;
    }

    @media screen and (max-width: 480px){
        font-size: ${StyleDataObj.H3fontSize}px;
    }
` 