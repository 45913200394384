import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { FooterContainer, FooterLinkWrapper, FooterWrap, FooterLinksContainer, 
FooterLinkItems, FooterLinkTitle, FooterLink, FooterLinkHref} from './FooterElements';

import { SocialMedia, SocialMediaWrap, SocialIcons, SocialIconsLinks, 
    SocialLogo, WebsiteRights} from './FooterElements';
    
import { animateScroll as scroll } from 'react-scroll/modules';

const Footer = () => { 

    const toggleHome = ()=>{
        scroll.scrollToTop();
        };

  return (
      <FooterContainer>
          <FooterWrap>
              <FooterLinksContainer>
                  <FooterLinkWrapper>
                     <FooterLinkItems>
                        <FooterLinkTitle>BUSINESS TRENDS ACADEMY </FooterLinkTitle>
                        <p>Friedrichstraße 94</p>
                        <p>10117 Berlin</p>
                        <p>Deutschland</p>
                        <br></br> <br></br>
                        <p>0800 9999 101</p> 
                     </FooterLinkItems> 

                     <FooterLinkItems>
                        <FooterLinkTitle>Weiterbildungen</FooterLinkTitle>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Projekt- & Changemanagement</FooterLinkHref>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Coaching & Training</FooterLinkHref>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Personalmanagement</FooterLinkHref>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Digitales Marketing</FooterLinkHref>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">IT Kompetenzen</FooterLinkHref>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Buchhaltung & Office</FooterLinkHref>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Soziales</FooterLinkHref> 
                     </FooterLinkItems>
                  </FooterLinkWrapper> 

                  <FooterLinkWrapper>
                     <FooterLinkItems>
                        <FooterLinkTitle>Weitere Leistungen</FooterLinkTitle>
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Firmenseminare</FooterLinkHref> 
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Coaching</FooterLinkHref> 
                        <FooterLinkHref  href="https://www.bta-weiterbildung.de/weiterbildungen" target="_blank">Bildungsgutschein</FooterLinkHref> 

                        {/* <FooterLink to="signin">Testimonials</FooterLink>
                        <FooterLink to="signin">Careers</FooterLink>
                        <FooterLink to="signin">Investors</FooterLink>
                        <FooterLink to="signin">Term of Service</FooterLink>  */}
                     </FooterLinkItems> 

                     <FooterLinkItems>
                     <FooterLinkHref  href="https://www.bta-weiterbildung.de/unternehmen" target="_blank"><FooterLinkTitle>Über die BTA </FooterLinkTitle></FooterLinkHref>
                        {/* <FooterLinkTitle>About us </FooterLinkTitle>
                        <FooterLink to="signin">How it works</FooterLink>
                        <FooterLink to="signin">Testimonials</FooterLink>
                        <FooterLink to="signin">Careers</FooterLink>
                        <FooterLink to="signin">Investors</FooterLink>
                        <FooterLink to="signin">Term of Service</FooterLink>  */}
                     </FooterLinkItems>
                  </FooterLinkWrapper>
              </FooterLinksContainer> 

              <SocialMedia>
                  <SocialMediaWrap>
                      <SocialLogo to="/" onClick={toggleHome}>
                      <FooterLinkHref  href="https://www.bta-weiterbildung.de" target="_blank"><FooterLinkTitle>BTA </FooterLinkTitle></FooterLinkHref>
                      </SocialLogo>
                      <WebsiteRights> BTA § {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                      <SocialIcons>
                          <SocialIconsLinks href="/" target="_blank"
                          arial-label="Facebook" >
                              <FaFacebook/>
                          </SocialIconsLinks> 

                          <SocialIconsLinks href="/" target="_blank"
                          arial-label="Instagram" >
                              <FaInstagram/>
                          </SocialIconsLinks> 

                      </SocialIcons>
                  </SocialMediaWrap>
              </SocialMedia>
          </FooterWrap>
      </FooterContainer>
  );
};

export default Footer;
