import styled from "styled-components";
import { StyleDataObj } from "../StyleData"; 

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const TitleTextNoAccordion = ({title, subtitle, id, text}) => { 

    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
  <MainContainer id={id}>
    <Row1>
        <MainH2> {title} </MainH2> 

        <Text1>
            {text}
        </Text1>

        {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            >
            <Text1> Hier weiter lesen ... </Text1> 
            <Typography sx={{ color: 'text.secondary' }}></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Text1>
            {text}
            </Text1> 
            </AccordionDetails>
        </Accordion> */}
        
    </Row1>
  </MainContainer>
  );
};

export default TitleTextNoAccordion; 


export const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: 800px; */
    position: relative;
    z-index: 1; 
    margin-bottom: 20px;
    max-width: 1000px;
    
` 
export const Row1 = styled.div`
    
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
`  


export const MainH2 = styled.h2`
    word-wrap: break-word;
    color: black;
    font-size: ${StyleDataObj.H2fontSize}px;
    text-align: center;
    margin: 30px 0;

    @media screen and (max-width: 768px){
        font-size: ${StyleDataObj.H3fontSize}px;
    }

    @media screen and (max-width: 480px){
        font-size: ${StyleDataObj.H3fontSize}px;
    }
`  
 

export const Text1 = styled.p`
    color: black;
    display: flex;
    flex-direction: row;
    font-size: ${StyleDataObj.pfontSize}px;
    text-align: ${StyleDataObj.p_text_align};
    margin:10px 0 ;
    word-spacing: 2px;
    overflow: visible auto;
    text-align: justify; /* For Edge */
    


`