import React from 'react';
import {Container, Form, FormButton, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, Text, FormInputLong } from './KontaktElements';
import Kontaktformular from './Kontaktformular';
import { Email_js } from '../Email/email_js';

const Kontakt = () => {
  return (
      <>
        <Container>
            <FormWrap>
                <Icon to="/">Startseite</Icon>
                <Kontaktformular/>
                {/* <Email_js/> */}
            </FormWrap>
        </Container>
      </>
  );
};

export default Kontakt;
