import React, {useState} from 'react';
import AfA from '../components/arbeitswelt/Section4/AfA';
import GefMassnahmen from '../components/arbeitswelt/Section5/GefMassnahmen';
import MainSection from '../components/arbeitswelt/mainSection';
import VideoSection from '../components/arbeitswelt/Section1/video';
import Voraussetzung from '../components/arbeitswelt/Section3/Voraussetzung';
import VorteilQCG from '../components/arbeitswelt/Section2/VorteilQCG';
import Footer from '../components/Footer';

import Navbar from '../components/Navbar';
import { Sidebar } from '../components/Sidebar';
import BTA_Hilfe from '../components/arbeitswelt/Section7/BTA_Hilfe';
import BTAKurse from '../components/arbeitswelt/Section6/btaKurse';
import Kontaktformular from '../components/Kontakt/Kontaktformular';

const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () =>{
        setIsOpen(!isOpen)
    }

  return (
  <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle}/>
    <MainSection/> 
    <VideoSection/>
    {/* <VorteilQCG/> 
    <Voraussetzung/> 
    <AfA/>
    <GefMassnahmen/> */}
    <BTAKurse/>
    <BTA_Hilfe/>
    <Kontaktformular/>

    {/* <HeroSection /> */}
    {/* <InfoSection {...homeObjOne}/>
    <InfoSection {...homeObj2}/>
    <Services/>
    <InfoSection {...homeObj3}/>  */}
    <Footer/>
  </>
  );
};

export default Home;
