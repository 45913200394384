import styled from "styled-components";
import React, {useState} from 'react'; 
import Video from '../../../videos/Qualifizierungschacengesetz_BTA_2022_02.mp4' 
import { StyleDataObj } from "../StyleData";

import TitleText from "../TitleSubtitle/TitleText";
import SubtitleText from "../TitleSubtitle/SubtitleText";
import TitleTextNoAccordion from "../TitleSubtitle/TitleTextNoAccordion";

import { Arbeitswelt40 as Text01 } from '../Data';
import { Personlichkeitsentwicklung as Text02 } from '../Data';
import { WerBinIch as Text03 } from '../Data';
import { DigitaleTools as Text04 } from '../Data'; 
import { Einzelcoaching_GruppenkursMix as Text05 } from '../Data';
import { BeruflicheNeuorientierung as Text06 } from '../Data';
import { IndividuellesBewerbungscoaching as Text07 } from '../Data';
import { ForderungDerMassnahme as Text08 } from '../Data';


import Pic1 from '../../../images/Education_Vector_4.png'
import AfALogo from '../../../images/AfALogo.png'
import Pic3 from '../../../images/undraw_multitasking_hqg3.svg'






const VideoSection = () => { 

    const [hover, setHover] = useState(false)
    const [alt, setAlt] = useState("false") 

    const videoSrc = Video;
    const poster = Pic1;

    const onHover = () =>{
        setHover(!hover)
    }

  return (
  <MainContainer id='video'>
    {/* <Row1>
        <MainH2>
            Alles was Sie wissen müssen
        </MainH2>
    </Row1> */}
    {/* <Row2>
        <ServicesWrapper>
            <Column1>
                <ListWrap>
                    <ListElement>Was ist das QCG</ListElement>
                    <ListElement>Was bringt das QCG</ListElement>
                    <ListElement>Voraussetzungen</ListElement>
                    <ListElement>Geförderte Maßnahmen</ListElement>
                    <ListElement>QCG Träger. BTA</ListElement>
                    <ListElement>Agentur für Arbeit</ListElement>
                    <ListElement>Antragstellung</ListElement>
                </ListWrap>
            </Column1>
            <Column2>
                <VideoPlayer 
                src={videoSrc}
                // poster={poster}
                width="720"
                height = "420"
                />
            </Column2>
        </ServicesWrapper>
    </Row2>  */}
    <Row3> 
        <TitleTextNoAccordion title={Text01.title} text={Text01.text} id={Text01.id}/>

        <ImgWrap>
            <Img src="https://cdn.pixabay.com/photo/2018/03/27/21/43/startup-3267505__340.jpg" alt="DesignYourLife"/>
            <SubtitleText title={Text02.subtitle} text={Text02.text} id={Text02.id}/> 
        </ImgWrap>

        <ImgWrap>
             <SubtitleText title={Text04.subtitle} text={Text02.text} id={Text02.id}/> 
            <Img src="https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTM3fHxkaWdpdGFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60" 
            alt="digitaleTools"/>
        </ImgWrap>  

        {/* <SubtitleText title={Text02.subtitle} text={Text02.text} id={Text02.id}/> */}
        <SubtitleText title={Text03.subtitle} text={Text02.text} id={Text02.id}/>
        {/* <SubtitleText title={Text04.subtitle} text={Text02.text} id={Text02.id}/>  */}

        <TitleText title={Text05.title} text={Text05.text} id={Text05.id}/> 
        <ImgWrap>
            <Img src="https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            alt="Coaching"/> 

            <Img src="https://cdn.pixabay.com/photo/2017/07/31/11/29/people-2557451_1280.jpg"
            alt="Coaching"/>
        </ImgWrap>

        <TitleText title={Text06.title} text={Text06.text} id={Text06.id}/>

        <TitleText title={Text07.title} text={Text07.text} id={Text07.id}/>

        <ImgWrap>
            <Img src={AfALogo} alt="AfALogo"/>
            <SubtitleText title={Text08.subtitle} text={Text08.text} id={Text08.id}/> 
        </ImgWrap>

         


    </Row3>
  </MainContainer>
  );
};

export default VideoSection; 


export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 1; 
` 
export const Row1 = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
`  

export const Row2 = styled.div`
    /* height: 800px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    /* @media screen and (max-width: 1000px){
        height: 900px;
    } 
    
    @media screen and (max-width: 768px){
        height: 1100px;
    }

    @media screen and (max-width: 480px){
        height: 1300px;
    } */
` 

export const Row3 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
` 


export const Column1 = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 480px){
        margin-left: 40px;
    }
    
` 

export const MainH2 = styled.h2`
    
    color: black;
    font-size: ${StyleDataObj.H2fontSize}px;
    text-align: center;
    margin-bottom: 20px;

    @media screen and (max-width: 768px){
        font-size: ${StyleDataObj.H2fontSize}px;
    }

    @media screen and (max-width: 480px){
        font-size: ${StyleDataObj.H2fontSize}px;
    }
`  

export const MainH3 = styled.h3`
    
    color: black;
    font-size: ${StyleDataObj.H3fontSize}px;
    text-align: center;
    margin: 10px;

` 

export const Text1 = styled.p` 
    color: black;
    font-size: ${StyleDataObj.pfontSize}px;
    text-align: ${StyleDataObj.p_text_align}; 
    margin: 20px 0;
`


export const Column2 = styled.div` 
    width: 600px;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 768px){
        width: 500px;
    }
    @media screen and (max-width: 576px){
        width: 400px;
    }
    @media screen and (max-width: 400px){
        width: 350px;
    }
` 

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`  

export const ImgWrap = styled.div` 
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const Img = styled.img` 
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`

export const ListWrap = styled.ol` 
    display: flex;
    flex-direction: column;
    align-self: center;
`

export const ListElement = styled.li` 
    margin-bottom: 10px;
` 

export const ServicesWrapper= styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`