import React, { useEffect, useState } from 'react';
import {FaBars} from "react-icons/fa"
import { 
    Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks,
    NavBtn, NavBtnLink
} from './NavbarElements'; 
import { IconContext } from 'react-icons/lib'; 

import {animateScroll as scroll} from 'react-scroll';


/* smooth={true} : langsame Übergang zum gelinken Bereich
duration={500} : wie schnell Übergang
spy={true} : zeigt wo wir sind (Link-Text wird unterstrichen, wenn wir in diesem Bereich sind)
                
exact = "true" 
offset={-80} : PufferAbstand */


const Navbar = ({toggle}) => {

  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = ()=>{
    if (window.scrollY >=80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  } 

  useEffect( ()=>{
    window.addEventListener('scroll', changeNav)
  }, []) 

  const toggleHome = ()=>{
    scroll.scrollToTop();
  };

  return (
  <>
  <IconContext.Provider value={{color: "#fff"}}>
    <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}>Arbeitswelt 4.0</NavLogo>
          <MobileIcon onClick={toggle}>
              <FaBars/>
          </MobileIcon>
          <NavMenu>
              <NavItem>
                <NavLinks to="Einzelcoaching_GruppenkursMix" 
                smooth={true} duration={500} spy={true} 
                exact = "true" offset={-80}
                > Einzelcoaching</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="IndividuellesBewerbungscoaching"
                smooth={true} duration={500} spy={true} 
                exact = "true" offset={-80}
                >Bewerbungscoaching</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="ZertifizierteBildungsträger"
                smooth={true} duration={500} spy={true} 
                exact = "true" offset={-80}
                >Bildungsträger</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="antragstellung"
                smooth={true} duration={500} spy={true} 
                exact = "true" offset={-80}
                >Antragstellung</NavLinks>
              </NavItem>
          </NavMenu>
          <NavBtn>
              <NavBtnLink to="/kontakt">Kontakt</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
    </Nav>
    </IconContext.Provider>
  </>
  );
};

export default Navbar;
