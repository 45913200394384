export const AfaObj = {
    id: 'AfA',
    topLine: "Premium Bank",
    title: "Agentur für Arbeit",
    text: "Die Agentur für Arbeit vor Ort ist der erste Ansprechpartner rund um die Förderung des Qualifizierungschancengesetzes. Die Qualifizierungsoffensive der Arbeitsagentur hilft ArbeitgeberInnen auch in Zukunft wettbewerbsfähig zu bleiben und die unternehmerischen Herausforderungen von morgen zu bewältigen, in dem sie in die Qualifikation ihrer MitarbeiterInnen investieren. Mit dem Qualifizierungschancengesetz 2022 und über die Agentur für Arbeit gibt es eine gesetzliche Regelung, um eine noch höhere Förderung zu erhalten, als es bisher möglich war sowie weitere Förderungsmöglichkeiten für Unternehmen. Durch eine Förderung über das Qualifizierungschancengesetz 2022 ist nun ein erweiterter Kreis an Arbeitnehmer/innen dazu berechtigt, die Möglichkeit des Qualifizierungschancengesetz Bildungsgutscheins zu nutzen, um an Weiterbildungen teilzunehmen. Durch den Qualifizierungschancengesetz Bildungsgutschein entfällt der Großteil der Kosten für die MitarbeiterInnen sowie für den/die ArbeitgeberInnen für die Teilnahme an den Qualifizierungsmaßnahmen der Agentur für Arbeit. Unter gewissen Voraussetzungen werden sogar bis zu 100 Prozent der Kosten von der Arbeitsagentur Weiterbildung Qualifizierungsoffensive abgedeckt. Auch wird das Arbeitsentgelt durch die Arbeitsagentur Weiterbildung Qualifizierungsoffensive bezuschusst.",
}

export const GefMassnObj = {
    id: 'gef_Maßnahmen',
    topLine: "Premium Bank",
    title: "QCG 2022: geförderte Maßnahmen",
    text: "Welche Schulungen bezahlt das Arbeitsamt? Diese Frage ist eine, der am häufigsten gestellten Fragen rund um die vom Qualifizierungschancengesetz (QCG) geförderten Maßnahmen. Die Weiterbildungsangebote der Agentur für Arbeit 2022 im Rahmen der Qualifizierungsoffensive-Fortbildungen sind äußerst umfangreich. Die MitarbeiterInnen der Bundesagentur für Arbeit und des Jobcenters beraten InteressentInnen zu den verschiedenen Möglichkeiten der finanziellen Förderung beruflicher Weiterbildung. Die Qualifizierungschancengesetz Maßnahmen müssen 2022 eine neue bestimmte Anzahl an Stunden umfassen, damit diese förderfähig sind. In welcher Form die Agentur für Arbeit Weiterbildungsangebote 2022 stattfinden, wird dabei jedoch nicht vorgegeben. Daher wird sowohl die Teilnahme an Schulungen, Kursen sowie online Weiterbildungen gefördert. Um das Ziel des Qualifizierungschancengesetzes zu erreichen, werden vor allem Maßnahmen und Weiterbildungen rund um das Qualifizierungschancengesetz Digitalisierung 2022 gefördert. So können durch die Qualifizierungsoffensive digitale Kompetenzen erworben und erweitert werden, um mit dem strukturellen Wandel und der Digitalisierung auf dem Arbeitsmarkt mithalten zu können."
} 

export const VorteilQCG_ArbeitgeberInnen = {
    id: 'VorteilQCG_ArbeitgeberInnen',
    topLine: "Premium Bank",
    subtitle: "Für ArbeitgeberInnen",
    text: "Auf den ersten Blick erscheint es vielleicht so, dass nur ArbeitnehmerInnen aus dem Qualifizierungschancengesetz Vorteile ziehen können. Dem ist jedoch keinesfalls so. Die Statistik zeigt, dass ArbeitgeberInnen ebenso von der Gesetzeslage profitieren. Die ständigen Bewegungen und Entwicklungen in allen Wirtschaftssektoren, die Digitalisierung sowie der allgemeine Fachkräftemangel stellen eine enorme Herausforderung für die Unternehmen, genauso wie für die MitarbeiterInnen, dar. Um das Unternehmen so zukunftsfähig wie möglich zu gestalten, ist es daher sinnvoll das Qualifizierungschancengesetz 2022 zu nutzen. So beinhaltet die Förderung für Weiterbildung unter anderem eine intensive Qualifizierungsberatung, die individuell auf das Unternehmen abgestimmt ist und Entwicklungspotenzial deutlich macht. Die Weiterbildungskosten für ArbeitgeberInnen wie die Übernahme von Fortbildungs- und Lehrgangskosten sowie Zuschüsse zum Arbeitsentgelt werden durch die Qualifizierungsoffensive Weiterbildung der Arbeitsagentur gefördert. Qualifizierungschancengesetz und Kurzarbeit schließen sich dabei keinesfalls aus, ganz im Gegenteil. Das Qualifizierungschancengesetz während Kurzarbeit lässt sich ebenso anwenden wie im Regelbetrieb. Durch die Anwendung des Qualifizierungschancengesetz 2022 in der Kurzarbeit kann die Zeit der Kurzarbeit sinnvoll genutzt werden, um die MitarbeiterInnen auf neue Aufgaben und Herausforderungen im strukturellen Wandel vorzubereiten. Die Qualifizierungsoffensive am Arbeitsmarkt ist eine der besten Möglichkeiten, um dem Fachkräftemangel entschieden entgegenzutreten. Das Nutzung des Qualifizierungschancengesetz während Corona ermöglicht zusätzlich einen erweiterten Zugang zu Weiterbildungsmöglichkeiten, sodass nun noch mehr ArbeitgeberInnen und ArbeitnehmerInnen die Qualifizierungschancengesetz Ziele erreichen können.",
} 

export const VorteilQCG_ArbeitnehmerInnen = {
    id: 'VorteilQCG_ArbeitnehmerInnen',
    topLine: "Premium Bank",
    subtitle: "Für ArbeitgeberInnen",
    text: "Für ArbeitnehmerInnen eröffnen sich mit der Qualifizierungsoffensive der Bundesregierung ganz neue Chancen. Dank des Qualifizierungschancengesetzes 2022 gibt es nun einen Fortbildungsanspruch für ArbeitnehmerInnen. Im Rahmen einer ArbeitnehmerInnen Weiterbildung können diese ihre Kenntnisse erweitern, Fähigkeiten ausbauen und wichtige Qualifikationen erwerben, um das eigene Portfolio interessanter zu gestalten. Die Anforderungen, welche der strukturelle Wandel und die Digitalisierung mit sich bringen, lassen sich durch Qualifikationen und Weiterbildungen einfacher bewältigen. Dabei lässt sich das Qualifizierungschancengesetz während Kurzarbeit ebenso anwenden, wie im normalen Berufsalltag. Durch eine MitarbeiterInnen Weiterbildung ist es möglich die Kurzarbeit sinnvoll zu nutzen und diese zu überbrücken. Auf das Kurzarbeitergeld muss durch die Inanspruchnahme des Qualifizierungschancengesetz für Kurzarbeit nicht verzichtet werden. Der Anspruch auf Kurzarbeitergeld bleibt auch während einer ArbeitnehmerInnen Weiterbildung bestehen. Wer sich bereits über eine Festanstellung, auch ohne Berufsabschluss freuen kann, der sollte es trotzdem nicht versäumen über den Tellerrand hinaus in die Zukunft zu blicken. Die Qualifizierung für MitarbeiterInnen beinhaltet nämlich unter anderem verschiedene Weiterbildungen und Fortbildungen, um den Berufsabschluss nachzuholen und dadurch zur Fachkraft zu werden. Gerade nach einer längeren Elternzeit lässt sich das Qualifizierungschancengesetz für Elternzeit gut nutzen, um den Wiedereinstieg in den Job zu bewältigen, aber auch um sich auf alternative Aufgaben in einem neuen Arbeitsbereich vorzubereiten und notwendige Fähigkeiten zu erwerben."
} 

export const Video_Qualifizierungschancengesetz = {
    id: 'Video_Qualifizierungschancengesetz',
    topLine: "Premium Bank",
    subtitle: "Was ist das Qualifizierungschancengesetz?",
    text: "Das Qualifizierungschancengesetz trat bereits im Januar 2019 in Kraft. Einfach erklärt lässt sich das Qualifizierungschancengesetzt (QCG) als Berliner Qualifizierungsoffensive - Förderung der beruflichen Kompetenzen und Qualifikationen ansehen. Ziel ist es mit der Qualifizierungsoffensive Berlin Beschäftigten den Zugang zu Weiterbildungsmaßnahmen für ArbeitnehmerInnen zu ermöglichen. Grundsätzlich kann jede/jeder durch die Qualifizierungsoffensive der Bundesagentur für Arbeit Zugang zu Weiterbildungsangeboten erhalten, um mit dem allgemeinen und digitalen Strukturwandel mithalten zu können und durch Qualifikationen langfristig vor Arbeitslosigkeit geschützt zu sein. Die Anwendung des Qualifizierungschancengesetz Berlin findet unabhängig von der bisherigen Qualifikation eines/einer ArbeitnehmerIn, dem Alter und der Größe des Betriebes Anwendung. Durch das Qualifizierungschancengesetz 2022 haben nun mehr Beschäftigte die Chance auf Weiterbildung und dem Erwerb neuer Qualifikationen. Darüber hinaus wurden mit dem Qualifizierungschancengesetz Berlin mehr Menschen in der Arbeitslosenversicherung abgesichert. Ergänzt wird das Qualifizierungschancengesetz außerdem durch das Arbeit-Von-Morgen-Gesetz. Das Arbeit-Von-Morgen-Gesetz beinhaltet erweiterte Möglichkeiten der Förderleistungen."    

} 

export const Voraussetzungen = {
    id: 'voraussetzungen',
    topLine: "Premium Bank",
    title: "Voraussetzungen",
    text: "Mit dem Inkrafttreten des Qualifizierungschancengesetz 2022 werden die Lehrgangskosten für MitarbeiterInnen teilweise oder sogar vollständig erstattet. Für die Kostenübernahme gilt es jedoch einige Fördervoraussetzungen von Seiten der ArbeitnehmerInnen und ArbeitgeberInnen zu erfüllen. Zu den Voraussetzungen des Qualifizierungschancengesetzes zählt, neben der Festanstellung beim Arbeitgeber, dass die Weiterbildung mindestens 120 Stunden beträgt. Dabei muss sowohl die Weiterbildung als auch der Träger, wie die Business Trends Academy (BTA), für die Förderung zugelassen sein. Die Höhe der Zuschüsse für die Weiterbildungskosten und zum Arbeitsentgelt ist ebenfalls an Fördervoraussetzungen des Qualifizierungschancengesetzes geknüpft. Diese ist entsprechend der betrieblichen Rahmenbedingungen gestaffelt. Betreibe mit weniger als 10 MitarbeiterInnen bekommen bis zu 100 Prozent der Weiterbildungskosten erstattet sowie bis zu 75 Prozent Zuschuss zum Arbeitsentgelt während der Weiterbildung. Sind im Betrieb hingegen mehr als 2500 MitarbeiterInnen angestellt liegen die Kostenerstattungen und Zuschüsse bei 15 beziehungsweise 25 Prozent."

} 

export const BtaHilfe_Kursauswahl = {
    id: 'BtaHilfe_Kursauswahl',
    topLine: "Premium Bank",
    subtitle: "Wir helfen dir bei deiner Kursaufwahl ",
    text: "Als erfahrener und anerkannter Träger für Weiterbildungen kennen wir von der BTA die Herausforderungen für ArbeitgeberInnen und ArbeitnehmerInnen auf dem Arbeitsmarkt genau. Dank unserer Erfahrung und dem Wissen unserer ExpertInnen können wir dich individuell zu zukunftssicheren Berufsfeldern beraten und dich dabei unterstützen, die für dich passende Kurse zu finden. Wir suchen gemeinsam mit dir eine Weiterbildung, die wirklich zu dir passt und dich beruflich erfolgreich weiterbringt.  "
} 

export const BtaHilfe_Antragsstellung  = {
    id: 'BtaHilfe_Antragsstellung',
    topLine: "Premium Bank",
    subtitle: "So unterstützt dich die BTA bei deiner Antragsstellung auf Weiterbildungsförderung",
    text: "Was muss ich alles bei der Agentur für Arbeit einreichen, um das Qualifizierungschancengesetz für mich nutzen zu können? Unsere ExpertInnen beantworten dir Fragen wie diese. Wir unterstützen dich beim korrekten und fristgerechten Ausfüllen des Antrags, sodass du problemlos zeitnah deine Weiterbildung bei uns beginnen kannst. Weiterhin gibt es die Möglichkeit bei der Agentur für Arbeit einen Sammelantrag zu stellen, sofern mehrere MitarbeiterInnen an einer Weiterbildung teilnehmen möchten. Was es dabei zu beachten gilt und wie du einfach und unkompliziert den Sammelantrag stellst, zeigen wir dir gerne. "
} 

export const BTA_Traeger  = {
    id: 'BTA_Traeger',
    topLine: "Premium Bank",
    subtitle: "Zertifizierter Träger",
    text: "Die Business Trends Academy ist ein etablierter und langjähriger Anbieter von Weiterbildungen. Als Anbieter für Weiterbildungen mit Sitz in Berlin können unsere Kurse, Seminare und Weiterbildungen online, im virtuellen Präsenzunterricht in der gesamten Republik besucht werden. Dabei sind wir mit der offiziellen Qualifizierungschancengesetz Zertifizierung ausgezeichnet. Deine Teilnahme an unseren Kursen und Weiterbildungen wird daher von der Agentur für Arbeit über Bildungsgutscheine gefördert. In unserem Portfolio als Qualifizierungschancengesetz zertifizierter Anbieter befinden sich derzeit über 60 geförderte und zertifizierte Weiterbildungen in den relevantesten Bereichen. So kannst du an der BTA wichtige IT-Kompetenzen, Coaching und Softskills aber auch hilfreiches Anwenderwissen im Bereich Digitales Marketing und Projektmanagement erwerben. Als langjähriger Anbieter von Weiterbildungen kennen wir den Arbeitsmarkt genau. Dabei zeichnen wir uns vor allem dadurch aus, dass du bei uns nicht nur theoretisches Wissen erhältst, sondern direkt von ExpertInnen aus der Praxis lernst. "
}

export const Arbeitswelt40  = {
    id: 'Arbeitswelt40',
    topLine: "Premium Bank",
    title: "Fit für die Arbeitswelt 4.0 ",
    text: "Du bist berufserfahren, nun aber ohne Job. Wie soll es weitergehen? Mit unserem „Fit für die Arbeitswelt 4.0“-Coaching schaffst du es, innerhalb von acht Wochen, die Midjobcrisis zu verlassen und dich fit für deinen beruflichen Neustart zu machen. In der Gruppe lernst du spannende andere Menschen kennen, die in einer ähnlichen Situation wie du selbst sind. Gemeinsam erarbeitet ihr Wege zu eurem individuellen Traumjob und lernt nebenbei noch Wesentliches über die Arbeitswelt von heute. Du schärfst dein Profil, stellst dich in deinen Bewerbungsunterlagen und in Social-Media-Netzwerken gut auf und wirst zusätzlich noch im persönlichen Einzelcoaching gefördert und gefordert, deinen Weg zu gehen. Auf ins neue Berufsleben! "

} 

export const Personlichkeitsentwicklung  = {
    id: 'Personlichkeitsentwicklung',
    topLine: "Premium Bank",
    subtitle: "Design your life – Persönlichkeitsentwicklung – Wann macht es Sinn?",
    text: "Wenn sich elementare Dinge in deinem Leben verändern oder verändern sollen! Der Wunsch nach einer beruflichen Veränderung kann viele Gründe haben. Berufliche Neuorientierung ist oft nötig nach einem Burnout bzw. aus gesundheitlichen Gründen oder aber auch aus dem Gefühl heraus, im Job auf der Stelle zu treten. Wenn du gerade nicht weißt, wie es weitergehen soll oder welchen Weg du wählen sollst – dann ist ein Life Coaching sinnvoll. Unsere „Arbeitswelt 4.0“-Gruppenmaßnahme hilft dir, Veränderungen zuzulassen und gestärkt in die neue Situation zu gehen. Bist du unglücklich mit deiner aktuellen Arbeitssituation und möchtest diese gerne ändern? Hierfür gibt es auch kreative Techniken als Hilfe bei beruflicher Neuorientierung, durch die du dein Leben aus unterschiedlichen Perspektiven betrachten kannst. Du designst dein Leben neu – Design Your Life ist eine Methode, die aus dem Design Thinking entstanden ist und dir einen konstruktiven Ansatz für die Gestaltung deiner Karriere und deines Lebens bietet. Du entwickelst verschiedene Prototypen deines Lebens und wirst diese gestalten und auch testen. So trittst du den Herausforderungen deiner Zukunft mit einem neuen Mindset entgegenüber – und kannst es erfolgreich annehmen. Unsere erfahrenen Coaches und Trainer zeigen dir, wie das geht und unterstützen dich. Gemeinsam schaut ihr auf deine Stärken und Schwächen und konzentriert auch auf das, was dich wirklich weiterbringt. Dein Selbstwertgefühl steigt, das Selbstbewusstsein ist gestärkt: Neue Wege tun sich auf und der berufliche Wiedereinstieg nach Burnout oder der Wiedereinstieg in den Beruf nach langer Pause gelingt langfristig. "

} 

export const WerBinIch  = {
    id: 'WerBinIch',
    topLine: "Premium Bank",
    subtitle: "Wer bin ich = Was ist der ideale Beruf für mich? – Brauch ich einen Persönlichkeitstest?",
    text: "Es gibt unzählige Persönlichkeitstest, aber kaum einer ist so umfangreich und so gut wissenschaftlich erarbeitet wie der LINC Personality Profiler Test. Hier wird deine Persönlichkeit ganzheitlich erfasst: Charakter, Motive und Kompetenzen auf Basis des psychologischen Standardmodells für Persönlichkeit – den BIG FIVE. In unserer Maßnahme „Arbeitswelt 4.0“ bildet dieser Test den Einstieg in deine Reise zum neuen Job. Hier siehst du noch einmal schwarz auf weiß, wo deine Stärken liegen, was du richtig gut kannst und du erhältst Impulse, wie du dieses Wissen nutzen kannst. Du besprichst dies persönlich und individuell mit deinem Coach. Die Ergebnisse sind ein Eckpfeiler für deine zukünftigen Bewerbungen."    
} 

export const DigitaleTools  = {
    id: 'DigitaleTools',
    topLine: "Premium Bank",
    subtitle: "Digitale Tools und Agile Arbeitswelt",
    text: "Das Besondere an der Maßnahme „Fit für die Arbeitswelt 4.0“ ist, dass es über ein reines Bewerbungstraining weit hinaus geht. Digitale Werkzeuge finden in allen Branchen Anwendung. Ein routinierter Umgang mit den gängigen Office- und Social-Media-Tools ist heute unumgänglich geworden und daher Teil des Trainings. Wie funktioniert Agiles Projektmanagement, wie arbeiten agile Teams? Wie funktioniert die Kommunikation in Unternehmen? Wie werden digitale Tools sinnvoll eingesetzt, welche werden genutzt und welche Rolle spielt die Datenschutzgrundverordnung (DSGVO)? Erfahrene TrainerInnen zeigen dir die neue digitale Welt. In deiner Gruppe wird sicher auch der oder die Person sein, die bereits Erfahrungen hat – Austausch bringt Wachstum! Zum Abschluss von „Fit für die Arbeitswelt 4.0“ erhältst du ein Zertifikat mit den erlernten Inhalten."
} 

export const Einzelcoaching_GruppenkursMix  = {
    id: 'Einzelcoaching_GruppenkursMix',
    topLine: "Premium Bank",
    title: "Einzelcoaching und Gruppenkurs Mix ",
    text: "Unser neuartiges und modernes „Fit für die Arbeitswelt 4.0“-Coaching besticht durch besonderen Aufbau. Der achtwöchige Kurs unterteilt sich in verschiedene Phasen. Zunächst lernt ihr euch in der Gruppe kennen. Eure BegleiterInnen für die nächsten 8 Wochen, erfahrene Coaches, stellen sich euch vor und sie bieten den Rahmen für ein gutes Gelingen der Kennlernphase. Nach dem geglückten Start geht es weiter in die Einzelcoachings. Hier werdet ihr von einem Coach individuell begleitet, so dass ihr für eure Themen noch einmal einen ganz persönlichen und vertraulichen Rahmen habt. Diese Einzelcoachings finden 1 – 2x wöchentlich mit deinem Coach statt. Darüber hinaus gibt es fachlichen Präsenz- und Onlineunterricht in der Gruppe. Selbststudium rundet das Ganze ab. Durch diese Kombination, die klar in einem Ablaufplan geregelt ist, entsteht ein wertvoller Mix aus hilfreichem Input, Selbstreflexion, Unterstützung und eigenem Handeln. "
} 

export const BeruflicheNeuorientierung  = {
    id: 'BeruflicheNeuorientierung',
    topLine: "Premium Bank",
    title: "Berufliche Neuorientierung mit 30 plus",
    text: "Du bist in deinem Job ausgebrannt. Du kennst ihn, er erfüllt dich nicht mehr. In manchen Fällen wurde die Arbeitsstelle auch einfach wegrationalisiert oder du kannst aufgrund von gesundheitlichen Einschränkungen nicht mehr so weitermachen, wie bisher. Um einen neuen beruflichen Weg zu gehen, gibt es keine Altersgrenze. Eine berufliche Neuorientierung mit 45 kann genauso erfolgreich verlaufen wie eine berufliche Neuorientierung mit 30 oder auch 50 Jahren. In der „Arbeitswelt 4.0“ erarbeitest du gemeinsam mit Gleichgesinnten im Team neue Möglichkeiten. Du wechselst die Perspektive, erhältst konstruktives Feedback und wächst daran. Hierbei begleiten dich Coaches und Trainer in der Gruppe, sie vermitteln Wissen und stärken so dein Selbstbewusstsein. Zusätzlich reflektierst du die neuen Erkenntnisse noch im individuellen Einzelcoaching. So gelingt die berufliche Neuorientierung in jedem Alter!"    

} 

export const IndividuellesBewerbungscoaching  = {
    id: 'IndividuellesBewerbungscoaching',
    topLine: "Premium Bank",
    title: "Mit dabei: Individuelles Bewerbungscoaching",
    text: "In der Gruppe entwickelst du deinen eigenen Prototyp für dein Leben. Auch wenn hier schon darüber diskutiert wird, bleiben sicher noch Fragen offen. Um diese aufzufangen, um dich zu motivieren, Unterlagen fertigzustellen und am Ball zu bleiben, dafür stehen die Einzelcoachings. Zusätzlich übst beim Bewerbungscoaching du hier ganz individuell, wie du mit deinem Know-How bei einem Arbeitgeber überzeugen kannst, wie du dich im besten Licht darstellst und wie du Gehaltsverhandlungen erfolgreich führst. Hier ist auch der Raum für ganz persönliche Themen, die eine Rolle für die berufliche Neuorientierung spielen. Mit deinem individuellen Coach machst du den Feinschliff für deine neu erstellten Bewerbungsunterlagen. Das individuelle Einzelcoaching rundet die Maßnahme ab und gibt dir ganz persönlich den Halt und Stärkung, das im Bewerbungstraining neu Erlernte erfolgreich umzusetzen."
}  

export const ForderungDerMassnahme  = {
    id: 'ForderungDerMassnahme',
    topLine: "Premium Bank",
    subtitle: "Förderung der Maßnahme – gefördertes Coaching",
    text: "Die Kurse und Coachings der BTA sind seit vielen Jahren von der Arbeitsagentur und den Jobcentern anerkannt. „Fit für die Arbeitswelt 4.0“ wird mit einem Aktivierungs- und Vermittlungsgutschein (kurz AVGS) gefördert. Jede/r Arbeitssuchende kann diesen Coaching AVGS beim zuständigen Amt beantragen. Ganz gleich ob es sich dabei um einen AVGS für Einzelcoaching oder einen AVGS für Bewerbungscoaching handelt. Wir möchten, dass dein Wiedereinstieg in den Beruf erfolgreich gelingt – und mit dem Besuch dieser Maßnahme legst du den Grundstein zu diesem Erfolg. Hol dir jetzt dein gefördertes Coaching! "    
} 