import './App.css';
import Home from './pages';
import KontaktPage from './pages/kontakt';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";

/* In react-router-dom v6, "Switch" is replaced by routes "Routes". You need to update the import from
import { Switch, Route } from "react-router-dom";
to
import { Routes ,Route } from 'react-router-dom';
You also need to update the Route declaration from
<Route path="/" component={Home} />
to
<Route path='/welcome' element={<Home/>} />
*/



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} exact />
          <Route path="/kontakt" element={<KontaktPage/>} exact/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
