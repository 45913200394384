import styled from "styled-components";
import React, {useState} from 'react'; 
import BtaLogo from '../../../images/bta_logo.png'
import Sandra from '../../../images/Sandra.jpg'
import { StyleDataObj } from "../StyleData";


const MainSection = () => { 

    const [hover, setHover] = useState(false)
    const [altBtaLogo, setAltBtaLogo] = useState("false") 

    const onHover = () =>{
        setHover(!hover)
    }

  return (
    // <ImgWrap style={{border: '1px solid #ccc', marginBottom: 10}}>

  <MainContainer id='home'>
    <ServicesWrapperBildText>
        <Column id='btaLogoColumn'>
            <ImgWrap>
                <Img style={{width: '50%'}} src={BtaLogo} alt={altBtaLogo}/> 
            </ImgWrap>
        </Column> 

        <Column>
            <MainH1>
                <TextBlack>
                Bereit für Erfolge
                </TextBlack>
                <TextRed>
                AVGS-geförderte Coachingmaßnahme in Teilzeit
                </TextRed>
                {/* <TextDate>Start: 28.03. & 25.04.2022</TextDate> */}
            </MainH1>
        </Column>
    </ServicesWrapperBildText> 

    <div>
    <Wrapper>
        <Column>
            <Text>
                <Questions>
                    Wie geht es beruflich mit mir weiter?
                    <br/> 
                    Kann ich noch einmal neu durchstarten?
                    <br/>
                    Wie kann ich sinnerfüllt arbeiten?
                </Questions>
            </Text>
        </Column>
    </Wrapper>
    </div>

    <Wrapper>
        <Column>
            <Text>
            <h1>Mit Gruppen – und Einzelcoaching zum Erfolg: <br/>
    ARBEITSWELT 4.0 – Wege aus der Mid-Job-Crisis </h1>
        </Text>
        </Column>
    </Wrapper>
    
    <ServicesWrapper>
        <ServicesWrapperBildText> 
            <Column>
                <TextDate>
                    vom 25.04.2022 bis zum 13.06.2022
                </TextDate>
            </Column>

            <Column justifyContent="right">
                <ImgWrap>
                    <Img src="https://cdn.pixabay.com/photo/2020/02/20/06/24/team-4864038_1280.jpg"
                    alt="altBtaLogo"/> 
                </ImgWrap>
            </Column> 
        </ServicesWrapperBildText> 
    </ServicesWrapper>

    <Wrapper>
    <Column>
        <Text>
            <p> Ein völlig neues Konzept – <br></br>
                8 Wochen Input und Arbeit an deiner Zukunft.
                <br></br> <br></br>
                Du profitierst von der Gruppe, datest dich mit den Themen der Arbeitswelt 4.0: Agiles Umfeld, Leadership, Coaching, Design Thinking, Social Media – up und findest deinen Weg zu deinem Traumberuf. 
                <br></br> <br></br>
                Du wirst vor Ort in einer Gruppe unterrichtet, zusätzlich auch online geschult, erarbeitest selbst und auch mit anderen Gruppenmitgliedern Themen und baust dein eigenes Netzwerk auf.
            </p>

        </Text>
    </Column>
    </Wrapper>

    <ServicesWrapperBildText> 
        <Column>
            <ImgWrap>
                <Img src="https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGNvYWNoaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60"
                 alt="altBtaLogo"/> 
            </ImgWrap>
        </Column> 
        <Column>
            <Text>
                Bei den Gruppenanteilen wirst du ständig von proffesionellen und praxisorientierten Coaches und Trainern begleitet.
            </Text>
        </Column>
    </ServicesWrapperBildText>  

    <ServicesWrapperBildText>        
        <Column>
            <Text>
            Zusätzlich wirst du von deinem/deiner persönlichen Coach individuell 20 Unterrichtseinheiten begleitet, um dein individuelles Profil zu schärfen.
            </Text>
        </Column>
        <Column>
            <ImgWrap>
                <Img src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGNvYWNoaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60"
                 alt="altBtaLogo"/> 
            </ImgWrap>
        </Column>
    </ServicesWrapperBildText> 

    <Wrapper>
        <Column>
        <Text>
        <p> 
        Unser Ziel ist es, dich in 8 Wochen fit zu machen für die Arbeitswelt 4.0 – und viel mehr: Wir möchten, dass du deinen Traumjob findest und weißt, welchen Weg du gehen wirst!
        </p>
    </Text>
        </Column>
    </Wrapper>

    <ServicesWrapperBildText>        
        <Column>
            <Text>
                <p>
                Wann geht es los? 25. April 2022 
                <br></br> <br></br>
                Wo: Zentral in Berlin Mitte, am S- und U-Bahnhof Friedrichstraße, der Unterricht und die Coachings finden aber teilweise auch online statt.
                <br></br> <br></br>
                Wie oft: Montags bis Freitags jeweils 2 – 4 Stunden
                <br></br> <br></br>
                Mit wem: Mit Gleichgesinnten und begleitet von professionellen und erfahrenen Coaches und praxisorientierten Trainern
                </p>
            </Text>
        </Column>
        <Column justifyContent="right">
            <ImgWrap>
                <DateWrapper>
                    <p>
                        Am 25. April geht es los!
                    </p>
                </DateWrapper> 
            </ImgWrap>
        </Column>
    </ServicesWrapperBildText>

    <ServicesWrapperBildText>
    <Column>
            <ImgWrap>
                <Img src={Sandra}
                 alt="altBtaLogo"/> 
            </ImgWrap>
        </Column>        
        <Column>
            <Text>
                <InfoText>
                    Ich berate dich gerne, ruf uns an und vereinbare einen Termin!
                    Beste Grüße von Sandra!
                    <br></br> <br></br>
                    030 95 9999 100
                    <br></br>
                    coaching@bta-weiterbildung.de

                </InfoText> 
            </Text>
        </Column>
        
    </ServicesWrapperBildText>
    
  </MainContainer>
  );
};

export default MainSection; 

export const Column = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent ? props.justifyContent : "left"};;
    margin-bottom: 20px;
    

    /* @media screen and (max-width: 1279px){
        height: 900px;
    } */

    /* @media screen and (max-width: 768px){
        height: 100px;
    }
    */
    @media screen and (max-width: 480px){
        justify-content: left;
    } 
`


export const InfoText = styled.p`

    font-size: 20px;
`

export const DateWrapper = styled.div`

    background-color: #eb868a;
    /* #e73138; */
    height: 150px;
    width: 150px;
    display: flex;

    align-items: center;
  
    margin: 20px auto;
    padding: 10px;
    line-height: 1;

    /* white-space: nowrap; normal; pre */
`

export const Text = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    max-width: 1000px;

    margin: 20px auto;
    line-height: 1;
    white-space: normal;

    font-size: 16px;
    /* text-align: justify; */
    word-spacing: 2px;
    /* overflow: visible auto; */
    /* color: blueviolet; */
    

    @media screen and (max-width: 480px){
        justify-content: left;
    } 
`

export const Questions = styled.p`
    line-height: 2;
    font-size: small; 
`

export const Wrapper= styled.div`
    max-width: 1000px;
    margin: 0 auto;
    /* display: grid; */
    align-items: center;
    grid-gap: 16px;
    padding: 0 30px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;
        padding: 0 30px;
    }

    @media screen and (max-width: 480px){
        grid-template-columns: 1fr;
        padding: 0 30px;
        /* margin: 50px; */
        grid-gap: 26px;
    }
`

export const ServicesWrapper= styled.div`
    max-width: 1000px;
    margin: 0 auto;
    
    align-items: center;
    padding: 0 30px;

`

export const ServicesWrapperBildText= styled.div`
    max-width: 1000px;
    display: grid;
    grid-gap: 26px;
    grid-template-columns: 1fr 1fr;
    padding: 0 30px;

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;
        padding: 0 30px;
    }

    @media screen and (max-width: 480px){
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 30px;
        grid-gap: 26px;
    }
`

export const WrapperLogo= styled.div`

    /* display: grid;

    @media screen and (max-width: 480px){
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 30px;
        grid-gap: 26px;
    } */

    
`

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;

    background: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    /* height: 800px; */
    position: relative;
    z-index: 1; 
    margin-top: 50px;
` 



export const MainH1 = styled.h1`
    
    color: #fff;
    font-size: ${StyleDataObj.H1fontSize}px;
    text-align: center;
    font-family: sans-serif;

    @media screen and (max-width: 768px){
        font-size: ${StyleDataObj.H1fontSize}px;
    }

    @media screen and (max-width: 480px){
        font-size: ${StyleDataObj.H1fontSize}px;
    }
` 

export const TextRed = styled.div`
    color: red;
    font-style: italic;
    font-family: "ibm plex serif";
    font-size: 24px;
` 
export const TextBlack = styled.div`
    color: black;
    font-family: Inter, sans-serif;
` 

export const TextDate = styled.div`
    color: black;
    font-family: Inter, sans-serif;
    font-size: 15px;
    margin-top: 15px;
    font-style: italic;
` 

export const ImgWrap = styled.div` 
    /* max-width: 555px; */
    height: 100%;
    max-width: 250px;
    /* padding: 0 30px; */
`

export const Img = styled.img` 
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
` 

